<template>
  <div class="disclosure">
    <h1>EVolve NY Frequent Driver</h1>
    <h2>Promotion Terms and Conditions</h2>
    <p class="primary-color"><strong>Effective as of January 25, 2022 </strong></p>
    <p>
      Electrify America and the New York Power Authority (NYPA) are pleased to provide this promotional charging program
      to selected drivers. By clicking “Agree” below, you agree to participate in this promotion, subject to your
      compliance with these terms and conditions.
    </p>
    <p>
      Participation requires an Electrify America account, the Electrify America mobile app, enrollment in this EVolve
      NY Frequent Driver Charging Promotion, and acceptance of Electrify America’s Terms of Use. Your mobile carrier’s
      normal messaging, data, and other rates and fees will apply to your use of the Electrify America app.
    </p>
    <p>
      Immediately upon your registration and acceptance of these terms, you will be enrolled in a program that provides
      a discounted $0.20 per kWh rate for DC fast (CCS and CHAdeMO) charging on EVolve NY’s electric vehicle charging
      stations operated on Electrify America’s network. You will also receive Pass + pricing at Electrify America’s
      electric vehicle charging stations excluding any Arizona Public Service branded stations. This promotion’s pricing
      is valid through January 1, 2023, unless earlier terminated by Electrify America pursuant to these terms.
    </p>
    <p>
      After signing up, you can redeem your discounted charging during your initiation of a charging session at any
      EVolve NY or Electrify America charging station, exclusively by using the Electrify America app “Swipe to start”
      or NFC Pass feature with the appropriate plan selected. This discounted charging may not be initiated using the
      interface screen or buttons on the charger, through a vehicle’s application “Plug and Charge” functionality, or
      through any application or software other than the Electrify America app (such as your vehicle’s onboard software
      or integrated mobile app).
    </p>
    <p>
      There is no additional fee, purchase, or subscription required for your access to this promotion. Subject to the
      restrictions below, there is no limit on the number of eligible charging sessions or the kWh amount subject to
      these discounted rates.
    </p>
    <p class="primary-color">
      <strong>PLEASE NOTE THE FOLLOWING IMPORTANT LIMITATIONS ON YOUR USE OF THIS PROMOTION:</strong>
    </p>
    <p>
      You may <strong>only</strong> use this discounted charging for charging the vehicle associated with the activation
      code you received from NYPA when signing up for this promotion on your Electrify America account.
      <strong>No other vehicle is eligible to receive this credit through your account,</strong> whether owned by you or
      others.
    </p>
    <p>
      Discounted charging <strong>does not include</strong> any idle fees and their associated applicable taxes assessed
      at the charging station. You will be responsible for paying such fees through your account if you incur them
      during a charging session.
    </p>
    <p>
      You must follow all product, vehicle, safety, and technical documentation included with your vehicle when charging
      at an EVolve NY or Electrify America charging station.
    </p>
    <p>
      Once the term of this promotion has terminated or expired, you will not be able to reload, renew, or add more
      credit to this promotional plan. Please refer to the information on the <strong>“Plan Details”</strong> section of
      the app to keep track of the amount of time remaining in this promotion.
    </p>
    <p>
      This credit is not returnable or redeemable for any cash value, and may not be sold, loaned, or otherwise
      distributed to any third party for any reason. You may not transfer any portion of the credit to any other
      Electrify America account.
    </p>
    <p>
      You acknowledge that your use of Electrify America’s services is subject to the Electrify America Terms of Use
      available at
      <router-link class="link" :to="{ name: 'terms-en' }">https://www.electrifyamerica.com/terms</router-link> and that
      your use of the Electrify America mobile app is subject to the Terms of Use accessible by navigating to the
      <strong>Profile</strong> icon in top right hand corner of map, selecting <strong>“Legal”</strong> and then
      selecting <router-link class="medium" :to="{ name: 'terms-en' }">“Terms of Use”</router-link> &amp;
      <router-link class="medium" :to="{ name: 'privacy-en' }">“Privacy Policy.”</router-link> Please read these Terms
      of Use documents carefully for information about how you can pay through your account for idle fees and applicable
      taxes as well as for charging session time outside of the discounted charging offered under this promotion.
    </p>
    <p>
      You agree to comply with all applicable laws and regulations when using Electrify America services and when
      charging your vehicle at EVolve NY and Electrify America charging stations.
    </p>
    <p>
      Electrify America reserves the right to withhold, revoke, reduce, terminate, or suspend your access to all or any
      portion of this promotion, without notice, if Electrify America determines or suspects, in its sole discretion,
      that you: (a) are in violation of the Electrify America Terms of Use, these Promotion Terms and Conditions, or any
      other contract between you and Electrify America; (b) have engaged in charging sessions that are excessive,
      fraudulent, or otherwise indicate an unauthorized or unintended use of this promotion; or (c) have engaged in any
      illegal, fraudulent, tortious, injurious, harmful, or abusive conduct in your use of the Electrify America
      services, the Electrify America mobile app, or an Electrify America charging station.
    </p>
    <p>
      Electrify America reserves the right to end this promotion at any time before its automatic termination date for
      any reason, in its sole discretion, provided that Electrify America will send an email to the address associated
      with your account in the event of any change to the scheduled end date of this promotion. Following the
      termination or expiration of this program for any reason, your access to this promotion will immediately cease,
      and you may not use or redeem any remaining portion of the discounted charging time. At that time, your account
      will automatically convert to a basic Electrify America “Pass” account, at no additional fee or charge to you
      (neither one-time nor recurring). You may, but are not obligated to, sign up for a different type of subscription
      via the Electrify America mobile app.
    </p>
  </div>
</template>

<script>
export default {
  name: 'evolve-ny-frequent-driver-disclosure',
  metaInfo: {
    title: 'EVolve New York Frequent Driver Disclosure | Electrify America',
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/evolve-ny-frequent-driver-disclosure/' }],
  },
};
</script>
